export default {
	formatter: {
		dateTime: 'D MMM YYYY, HH:mm',
		date: 'D MMM YYYY',
		time: 'HH:mm',
		thousand: '.',
		decimal: ','
	},
	datePicker: {
		startOfWeekDay: '1',
		separator: ' - ',
		format: 'DD-MM-YYYY HH:mm',
		direction: 'ltr',
		label: {
			apply: 'Opslaan',
			cancel: 'Annuleer',
			week: 'W',
			customRange: 'Custom Bereik'
		},
		preview: {
			dateTime: 'd mmmm yyyy, HH:MM',
			date: 'd mmmm yyyy'
		},
		ranges: {
			lastQuarterHour: 'Afgelopen 15 min.',
			lastHour: 'Afgelopen uur',
			lastQuarterDay: 'Afgelopen 6 uur',
			lastDay: 'Afgelopen 24 uur',
			todayUntilNow: 'Vandaag tot nu',
			yesterday: 'Gisteren',
			lastWeek: 'Afgelopen week',
			lastMonth: 'Afgelopen maand',
			lastYear: 'Afgelopen jaar',
			day: 'Dag',
			week: 'Week',
			month: 'Maand',
			year: 'Jaar',
			minute_s: 'Minuut/Minuten',
			hour_s: 'Uur/Uren',
			day_s: 'Dag(en)',
			month_s: 'Maand(en)'
		}
	},
	month: {
		long: {
			'0': 'januari',
			'1': 'februari',
			'2': 'maart',
			'3': 'april',
			'4': 'mei',
			'5': 'juni',
			'6': 'juli',
			'7': 'augustus',
			'8': 'september',
			'9': 'oktober',
			'10': 'november',
			'11': 'december'
		},
		short: {
			'0': 'jan',
			'1': 'feb',
			'2': 'mar',
			'3': 'apr',
			'4': 'mei',
			'5': 'jun',
			'6': 'jul',
			'7': 'aug',
			'8': 'sep',
			'9': 'okt',
			'10': 'nov',
			'11': 'dec'
		}
	},
	week: {
		format: 'Week %{week}. %{year}',
		long: {
			'0': 'zondag',
			'1': 'maandag',
			'2': 'dinsdag',
			'3': 'woensdag',
			'4': 'donderdag',
			'5': 'vrijdag',
			'6': 'zaterdag'
		},
		short: {
			'0': 'zo',
			'1': 'ma',
			'2': 'di',
			'3': 'wo',
			'4': 'do',
			'5': 'vr',
			'6': 'za'
		}
	}
}
