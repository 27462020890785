<template>
	<v-container>
		<v-row class="d-flex flex-nowrap">
			<tile title="Profiel">
				<v-col class="pa-0 d-flex align-center">
					<v-avatar color="green lighten-4 mr-2" size="72" />
					<v-col class="pa-0">
						<div class="subtitle-2">Afbeelding</div>
						<v-file-input
							class="pt-0 mt-0"
							dense
							hide-details
							show-size
							label="Upload"
						/>
					</v-col>
				</v-col>
				<v-col class="px-0 pt-2 pb-5 d-flex align-center">
					<v-text-field v-model="firstName" hide-details label="Voornaam" />
					<v-text-field class="ml-3" v-model="lastName" hide-details label="Achternaam" />
				</v-col>
				<v-btn color="light-green lighten-4" disabled elevation="0">
					Wijzigingen Opslaan
				</v-btn>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile title="Email">
				<v-text-field
					v-model="email.new"
					placeholder="..."
					persistent-placeholder
					hide-details
					label="Nieuw Email Adres"
				/>
				<v-text-field
					v-model="email.copy"
					class="mt-3"
					placeholder="..."
					persistent-placeholder
					hide-details
					label="Nieuw Email Adres Herhalen"
				/>
				<v-btn class="mt-5" color="light-green lighten-4" disabled elevation="0">
					Wijzigingen Opslaan
				</v-btn>
			</tile>
			<tile title="Wachtwoord">
				<v-text-field
					v-model="password.new"
					placeholder="..."
					persistent-placeholder
					hide-details
					label="Nieuw Wachtwoord"
				/>
				<v-text-field
					v-model="password.copy"
					class="mt-3"
					placeholder="..."
					persistent-placeholder
					hide-details
					label="Nieuw Wachtwoord Herhalen"
				/>
				<v-btn class="mt-5" color="light-green lighten-4" disabled elevation="0">
					Wijzigingen Opslaan
				</v-btn>
			</tile>
		</v-row>
	</v-container>
</template>

<script>
import Tile from '../../components/Tile'

export default {
	name: 'Dashboard',
	components: { Tile },
	data: () => ({
		firstName: 'Jan',
		lastName: 'Jansen',
		password: {
			new: '',
			copy: ''
		},
		email: {
			new: '',
			copy: ''
		}
	})
}
</script>
