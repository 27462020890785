<template>
	<center-display :style="{ 'text-align': 'center' }">
		<pie
			:config="mergedConfig"
			:data="data"
			:style="{ height: '160px', 'text-align': 'left' }"
		/>
		<span class="pre-title" v-text="title" />
		<formatter :options="config.format" :data="total" />
	</center-display>
</template>

<script>
import CenterDisplay from '../CenterDisplay'
import Formatter from '../Formatter'
import Pie from './Pie'
import deepmerge from 'deepmerge'

export default {
	components: {
		CenterDisplay,
		Formatter,
		Pie
	},
	props: ['title', 'config', 'data'],
	watch: {
		data(data) {
			this.updateData(data)
		}
	},
	methods: {
		updateData(result) {
			if (result && result.total != undefined) {
				this.total = result.total
				return
			}

			let total = 0
			for (const entry in result) {
				total += result[entry] || 0
			}
			this.total = total
		}
	},
	data() {
		return {
			total: 0,
			mergedConfig: {}
		}
	},
	mounted() {
		this.updateData(this.data)
	},
	created() {
		this.mergedConfig = {
			format: Object.assign(
				{
					hidden: true
				},
				this.config.format || {}
			),
			options: deepmerge.all([
				{
					plotOptions: {
						pie: {
							customScale: 0.82,
							donut: {
								size: '0%',
								labels: {
									show: false,
									name: {
										show: false
									},
									value: {
										show: false
									},
									total: {
										show: false,
										showAlways: false
									}
								}
							}
						}
					}
				},
				this.config.options || {}
			]),
			series: this.config.series
		}
	}
}
</script>
