export default {
	chart: {
		background: 'transparent',
		toolbar: {
			show: false,
			tools: {
				download: '<img src="/images/download.svg">',
				zoomin: false,
				zoomout: false,
				pan: false,
				reset: false
			},
			export: {
				csv: {
					columnDelimiter: '	'
				}
			}
		}
	},
	grid: {
		borderWidth: 5
	},
	legend: {
		itemMargin: {
			vertical: 5
		}
	},
	tooltip: {
		followCursor: true,
		x: {
			format: 'd MMM yyyy, HH:mm:ss'
		}
	},
	theme: {
		mode: 'dark'
	},
	xaxis: {
		labels: {}
	},
	yaxis: {
		labels: {}
	},
	noData: {
		text: 'Loading...'
	}
}
