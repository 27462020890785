import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import { ENV } from '@/settings'

Vue.use(Vuex)

const debug = ENV !== 'prod'

const store = new Vuex.Store({
	modules: {},
	strict: debug,
	plugins: debug ? [createLogger()] : []
})

const register = (name, module) => {
	if (!store.hasModule(name)) store.registerModule(name, module)
}

const subscriptions = []
const subscribe = (name, mutation, callback) => {
	if (subscriptions.includes(name)) return
	subscriptions.push(name)

	store.subscribe(({ type }) => {
		if (type === mutation) callback()
	})
}

export default store

export { store, register, subscribe }
