<template>
	<div>
		<v-app-bar class="custom-header" app flat color="white">
			<v-container class="py-0 fill-height">
				<div class="d-flex align-center">
					<h1 class="headline font-weight-black">
						<span class="text--gradient--green">Sociale Energie</span>
					</h1>
					<div class="ml-5 d-none d-md-flex">
						<v-btn
							v-for="(button, index) of navItems"
							:key="index"
							text
							:to="button.to"
							class="button-fade mr-2"
							active-class="v-btn--silent-active"
							exact-active-class="light-green lighten-4"
						>
							{{ button.name }}
						</v-btn>
					</div>
				</div>

				<v-spacer />
				<v-menu open-on-hover bottom offset-y>
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex align-center" v-on="on" v-bind="attrs">
							<v-avatar color="green lighten-4 mr-2" size="36" />
							<div class="subtitle-2">
								{{ user.firstName || '—' }} {{ user.lastName || '—' }}
							</div>
							<v-chip class="ml-2 white--text" small color="light-green">
								Admin
							</v-chip>
						</div>
					</template>

					<v-list dense>
						<v-list-item :to="{ name: 'Profiel' }">
							<v-list-item-icon>
								<v-icon>mdi-cog</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Profiel Instellingen</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item href="https://se.scp.spectral.energy/" color="red darken-4">
							<v-list-item-icon>
								<v-icon>mdi-logout-variant</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Uitloggen</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-container>
		</v-app-bar>
		<v-app-bar class="custom-bottom-header" app flat dense color="grey darken-3 white--text">
			<v-container class="py-0 fill-height d-flex align-center">
				<transition name="fade">
					<v-select
						v-if="$route.meta.assetSelection"
						class="subtitle-2"
						v-model="selectedLocation"
						:items="locations"
						prepend-icon="mdi-map"
						solo
						dense
						dark
						hide-details
						item-color="light-green"
						@change="updatedLocation"
					/>
				</transition>
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-spacer />
				<v-btn icon>
					<v-icon class="white--text" v-text="'mdi-refresh'" />
				</v-btn>
			</v-container>
		</v-app-bar>
	</div>
</template>

<script>
import assets from '../../constants/_assets'

export default {
	name: 'TopHeader',
	data: () => ({
		selectedLocation: '0' // TODO: This should exist in a store -- otherwise it can be desyned
	}),
	props: {
		user: {
			type: Object,
			required: true
		},
		asset: {
			type: Object,
			required: true
		},
		routes: {
			type: Array,
			required: true
		}
	},
	methods: {
		updatedLocation() {
			this.$store.dispatch('asset/FETCH', { id: this.selectedLocation })
		}
	},
	watch: {
		'asset.id'() {
			this.selectedLocation = this.asset.id
		}
	},
	computed: {
		locations() {
			const data = []
			for (const id in assets) {
				data.push({
					value: Number(id),
					text: assets[id].location.name
				})
			}
			return data
		},
		navItems() {
			const data = []
			for (const route of this.routes) {
				if (route.meta && route.meta.hidden) continue
				// TODO: In the future we also need to check the required roles for this path
				data.push({
					name: route.name,
					to: { name: route.name }
				})
			}
			return data
		}
	}
}
</script>
