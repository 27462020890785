<template>
	<v-card class="custom-tile d-flex flex-column pa-4 ma-2 body-2">
		<v-container v-if="icon || title || tooltip" class="d-flex pa-0 ma-0 mb-2 subtitle-2">
			<v-icon v-if="icon" v-text="icon" class="mr-1" dense />
			{{ title }}
			<v-spacer />
			<v-tooltip v-if="tooltip" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-icon color="grey" dense v-bind="attrs" v-on="on">
						mdi-help-circle
					</v-icon>
				</template>
				<span v-text="tooltip" />
			</v-tooltip>
		</v-container>
		<div class="full">
			<slot />
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'Tile',
	props: {
		icon: {
			type: String,
			required: false
		},
		title: {
			type: String,
			required: false
		},
		tooltip: {
			type: String,
			required: false
		}
	}
}
</script>
