<template>
	<v-container>
		<v-row class="d-flex flex-nowrap">
			<tile title="Rapportage" icon="mdi-download">
				Op deze pagina wordt het mogelijk om verschillende type rapportages te downloaden,
				zowel over de sociale als de duurzame impact van Sociale Energie. Deze
				functionaliteit is nu nog niet beschikbaar.
			</tile>
		</v-row>
	</v-container>
</template>

<script>
// Components
import Tile from '../../components/Tile'

export default {
	name: 'Report',
	components: { Tile }
}
</script>
