import messages_EN from './en/messages'
import messages_NL from './nl/messages'
import numberFormats_EN from './en/numberFormats'
import numberFormats_NL from './nl/numberFormats'

const messages = {
	en: messages_EN,
	nl: messages_NL
}
const numberFormats = {
	en: numberFormats_EN,
	nl: numberFormats_NL
}

export { messages, numberFormats }
