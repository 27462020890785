var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex flex-nowrap"},[_c('tile',{attrs:{"title":"Map","icon":"mdi-map"}},[_c('v-row',[_c('v-col',[_c('leaflet-map',{staticClass:"rounded",style:({ 'z-index': 0 }),attrs:{"height":350,"zoom":11.5,"center":{ latitude: 52.337189, longitude: 4.915431 },"markers":_vm.markers}})],1)],1)],1),_c('tile',{staticClass:"col-4",attrs:{"title":"Contributie verdeling per project"}},[_c('pie',{staticClass:"d-flex align-center justify-center",attrs:{"config":_vm.config.contribution,"data":_vm.contributionProjectData}})],1)],1),_c('v-row',{staticClass:"d-flex flex-nowrap"},[_c('tile',{attrs:{"title":"Totale contributie"}},[_c('h1',{staticClass:"d-inline text--gradient--green"},[_vm._v(" "+_vm._s(_vm.$n(_vm.totalProduction * 0.035, 'currency'))+" ")])]),_c('tile',{attrs:{"title":"Totale opwek"}},[_c('h1',{staticClass:"d-inline text--gradient--green"},[_vm._v(_vm._s(_vm.formatter.get(_vm.totalProduction)))])])],1),_c('v-row',{staticClass:"d-flex flex-nowrap"},[_c('tile',{attrs:{"title":"Projecten"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.projects.headers,"items":_vm.projectItems,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{style:({ margin: '0 -5px' }),attrs:{"color":"primary","text":"","small":"","target":"_blank","href":item.href}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"ml-2",domProps:{"textContent":_vm._s('mdi-link')}})],1)]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":"light-green","rounded":"","height":"6","value":((_vm.getProduction(item.yearTotalProduction) * 0.035) / item.goal) *
								100}})]}},{key:"item.total",fn:function(ref){
								var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(_vm.getProduction(item.yearTotalProduction) * 0.035, 'currency'))+" ")]}},{key:"item.goal",fn:function(ref){
								var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.goal, 'currency'))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }