<template>
	<span class="value-formatter">
		<span v-if="prefix != null" class="sub" v-text="prefix" />
		<span class="main" v-text="value" />
		<span v-if="suffix != null" class="sub" v-text="suffix" :class="{ 'd-block': lineBreak }" />
	</span>
</template>

<script>
import NumberFormatter from '../../utils/NumberFormatter'

export default {
	name: 'Formatter',
	props: {
		options: {
			type: Object,
			required: true
		},
		data: {
			required: true
		},
		lineBreak: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			formatter: new NumberFormatter(this.$n.bind(this), this.options)
		}
	},
	computed: {
		prefix() {
			return this.formatter.getPrefix(this.data)
		},
		value() {
			if (isNaN(this.data)) return this.data
			return this.formatter.getValue(this.data)
		},
		suffix() {
			return this.formatter.getSuffix(this.data)
		}
	}
}
</script>

<style lang="scss">
.value-formatter {
	> span {
		margin: 0 5px;
		:first-child {
			margin-left: 0;
		}
		:last-child {
			margin-right: 0;
		}
	}

	.main {
		display: inline-block;
		font-size: 24px;
		font-weight: 700;
		height: 0;
	}

	.sub {
		margin: 0;
		font-size: 18px;
		opacity: 0.6;
		display: inline-block;
	}
}
</style>
