export default {
	formatter: {
		dateTime: 'D MMM. YYYY, HH:mm',
		date: 'D MMM. YYYY',
		time: 'HH:mm',
		thousand: ',',
		decimal: '.'
	},
	datePicker: {
		startOfWeekDay: '1',
		separator: ' - ',
		format: 'DD-MM-YYYY HH:mm',
		direction: 'ltr',
		label: {
			apply: 'Save Changes',
			cancel: 'Cancel',
			week: 'W',
			customRange: 'Custom Range'
		},
		preview: {
			dateTime: 'd mmmm yyyy, HH:MM',
			date: 'd mmmm yyyy'
		},
		ranges: {
			lastQuarterHour: 'Last 15 minutes',
			lastHour: 'Last hour',
			lastQuarterDay: 'Last 6 hours',
			lastDay: 'Last 24 hours',
			todayUntilNow: 'Today so far',
			yesterday: 'Yesterday',
			lastWeek: 'Last week',
			lastMonth: 'Last month',
			lastYear: 'Last year',
			day: 'Day',
			week: 'Week',
			month: 'Month',
			year: 'Year',
			minute_s: 'Minute(s)',
			hour_s: 'Hour(s)',
			day_s: 'Day(s)',
			month_s: 'Month(s)'
		}
	},
	month: {
		long: {
			'0': 'January',
			'1': 'February',
			'2': 'March',
			'3': 'April',
			'4': 'May',
			'5': 'June',
			'6': 'July',
			'7': 'August',
			'8': 'September',
			'9': 'October',
			'10': 'November',
			'11': 'December'
		},
		short: {
			'0': 'Jan',
			'1': 'Feb',
			'2': 'Mar',
			'3': 'Apr',
			'4': 'May',
			'5': 'Jun',
			'6': 'Jul',
			'7': 'Aug',
			'8': 'Sep',
			'9': 'Oct',
			'10': 'Nov',
			'11': 'Dec'
		}
	},
	week: {
		format: 'Week %{week}. %{year}',
		long: {
			'0': 'Sunday',
			'1': 'Monday',
			'2': 'Tuesday',
			'3': 'Wednesday',
			'4': 'Thursday',
			'5': 'Friday',
			'6': 'Saturday'
		},
		short: {
			'0': 'Sun',
			'1': 'Mon',
			'2': 'Tue',
			'3': 'Wed',
			'4': 'Thu',
			'5': 'Fri',
			'6': 'Sat'
		}
	}
}
