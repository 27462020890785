import User from '../../store/modules/User'
import Asset from '../../store/modules/Asset'
import { store, register } from '../../store'

const initBasicStore = () => {
	register('user', new User())
	register('asset', new Asset())

	store.dispatch('user/FETCH')
	store.dispatch('asset/FETCH', { id: 0 })
}

export { initBasicStore }
