<template>
	<div class="d-flex align-center">
		<b v-text="timeLabel" />
		<v-spacer />
		<v-btn-toggle
			v-model="selectedRange"
			dense
			borderless
			background-color="light-green lighten-5 text--grey text--darken-4"
			color="light-green"
		>
			<template v-for="item in ranges">
				<v-btn
					v-if="timeRange.range.available.includes(item.value)"
					:key="item.value"
					@click="setRange(item.value)"
				>
					{{ item.text }}
				</v-btn>
			</template>
			<v-btn v-if="timeRange.range.available.includes('custom')" class="daterange-button">
				<date-range-picker
					ref="picker"
					:locale-data="{
						firstDay: 1,
						separator: ' — ',
						monthNames: Object.values($t('month.long')),
						daysOfWeek: Object.values($t('week.short')),
						customRangeLabel: $t('datePicker.label.customRange'),
						cancelLabel: $t('datePicker.label.cancel'),
						applyLabel: $t('datePicker.label.apply'),
						weekLabel: $t('datePicker.label.week'),
						format: $t(
							`datePicker.preview.${settings.timePicker ? 'dateTime' : 'date'}`
						)
					}"
					:opens="'left'"
					:singleDatePicker="
						settings.singleDatePicker != undefined ? settings.singleDatePicker : false
					"
					:timePicker="settings.timePicker != undefined ? settings.timePicker : true"
					:timePickerIncrement="1"
					:timePickerSeconds="true"
					:timePicker24Hour="true"
					:showWeekNumbers="true"
					:showDropdowns="true"
					:autoApply="false"
					:ranges="false"
					v-model="period"
					:linkedCalendars="true"
					@update="setTimeRange"
				>
					<div slot="input">
						<v-icon v-text="'mdi-calendar'" />
					</div>
				</date-range-picker>
			</v-btn>
		</v-btn-toggle>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		timeRange: {
			required: true
		},
		settings: {
			type: Object,
			default: () => {
				return {
					singleDatePicker: false,
					timePicker: true
				}
			}
		}
	},
	computed: {
		timeLabel() {
			const from = moment(this.timeRange.from)
			const to = moment(this.timeRange.to)

			if (this.settings.singleDatePicker && this.timeRange.range.selected === 'custom') {
				return this.$t('datePicker.ranges.day') + ` (${from.format('ll')})`
			}

			switch (this.timeRange.range.selected) {
				case 'custom':
					return (
						this.$t('datePicker.label.customRange') +
						` (${from.format('ll HH:mm')} - ${to.format('ll HH:mm')})`
					)
				case 'day':
					return this.$t('datePicker.ranges.day') + ` (${from.format('ll')})`
				case 'week':
					return (
						this.$t('datePicker.ranges.week') +
						` - ${from.format('w')} (${from.format('ll')} - ${to.format('ll')})`
					)
				case 'month':
					return this.$t('datePicker.ranges.month') + ` (${from.format('MMMM, YYYY')})`
				case 'year':
					return this.$t('datePicker.ranges.year') + ` (${from.format('YYYY')})`
				default:
					return ''
			}
		}
	},
	created() {
		this.setPeriod()
		this.setSelectedRange(this.timeRange.range.selected)
	},
	data() {
		return {
			selectedRange: 'day',
			ranges: [
				{
					text: this.$t('datePicker.ranges.day'),
					value: 'day'
				},
				{
					text: this.$t('datePicker.ranges.week'),
					value: 'week'
				},
				{
					text: this.$t('datePicker.ranges.month'),
					value: 'month'
				},
				{
					text: this.$t('datePicker.ranges.year'),
					value: 'year'
				}
			],
			period: {
				startDate: moment().startOf('day'),
				endDate: moment().endOf('day')
			}
		}
	},
	methods: {
		setSelectedRange(range) {
			this.selectedRange = this.timeRange.range.available.indexOf(range)
		},
		setPeriod() {
			this.period.startDate = this.timeRange.from
			this.period.endDate = this.timeRange.to
		},
		setRange(range) {
			this.$emit('set-range', {
				range: range,
				currentWhenSame: true
			})
			this.setSelectedRange(range)
		},
		setTimeRange(data) {
			if (this.settings.timePicker) {
				this.$emit('set-time-range', {
					from: data.startDate,
					to: data.endDate,
					selectedRange: 'custom'
				})
			} else {
				this.$emit('set-time-range', {
					from: moment(data.startDate).startOf('day'),
					to: moment(data.endDate).endOf('day'),
					selectedRange: 'custom'
				})
			}
			this.setSelectedRange('custom')
		}
	}
}
</script>

<style lang="scss">
.vue-daterange-picker {
	.form-control {
		background: transparent;
		border: none;
	}
}

.daterange-button {
	z-index: 1;
	opacity: 1 !important;
}
</style>
