const unit = {
	watt: {
		kW: {
			suffix: ' kW',
			modifier: 1,
			start: 0,
			end: 1000
		},
		MW: {
			suffix: ' MW',
			modifier: 0.001,
			start: 1000,
			end: 1000000
		},
		GW: {
			suffix: ' GW',
			modifier: 0.000001,
			start: 1000000,
			end: Infinity
		}
	},
	wattHour: {
		kWh: {
			suffix: ' kWh',
			modifier: 1,
			start: 0,
			end: 1000
		},
		MWh: {
			suffix: ' MWh',
			modifier: 0.001,
			start: 1000,
			end: 1000000
		},
		GWh: {
			suffix: ' GWh',
			modifier: 0.000001,
			start: 1000000,
			end: Infinity
		}
	}
}

class NumberFormatter {
	constructor(
		$n,
		{
			prefix = '',
			suffix = '',
			decimals = 2,
			modifier = 1,
			formatter = null,
			unit = null,
			unitPrefix = null
		}
	) {
		this.$n = $n

		this.prefix = prefix
		this.suffix = suffix
		this.decimals = decimals
		this.modifier = modifier
		this.formatter = formatter // As defined in vue-i18n numberFormats
		this.unit = unit
		this.unitPrefix = unitPrefix // Apply a specific unit instead of calculating one
	}

	getter() {
		return (value = 0) => this.get(value)
	}

	get(value = 0) {
		const unitedData = unit[this.unit] ? this._getUnit(value) : {}
		const data = this._getData(value, unitedData)
		return data.prefix + data.value + data.suffix
	}

	getPrefix(value = 0) {
		const unitedData = unit[this.unit] ? this._getUnit(value) : {}
		const { prefix } = this._getData(value, unitedData)
		return prefix || this.prefix
	}

	getSuffix(value = 0) {
		const unitedData = unit[this.unit] ? this._getUnit(value) : {}
		const { suffix } = this._getData(value, unitedData)
		return suffix || this.suffix
	}

	getValue(value = 0) {
		const unitedData = unit[this.unit] ? this._getUnit(value) : {}
		const data = this._getData(value, unitedData)
		return data.value
	}

	_getUnit(value = 0) {
		const _value = Math.abs(value * this.modifier)
		if (this.unitPrefix) return unit[this.unit][this.unitPrefix]

		for (const id in unit[this.unit]) {
			const type = unit[this.unit][id]

			if (type.start <= _value && type.end >= _value) {
				return type
			}
		}

		return {}
	}

	_getData(value = 0, unitedData = {}) {
		const data = {}

		data.value = value * this.modifier * (unitedData.modifier || 1)
		if (this.formatter) {
			data.value = this.$n(data.value, this.formatter)
		} else {
			data.value = this.$n(data.value, { maximumFractionDigits: this.decimals })
		}

		data.suffix = unitedData.suffix || this.suffix
		data.prefix = unitedData.prefix || this.prefix

		return data
	}
}

export default NumberFormatter
