<template>
	<div class="full">
		<center-display :multiple-blocks="true" vertical="stretch" direction="column">
			<div class="full" :class="graphClass || {}" :style="graphStyle || {}">
				<apexchart
					ref="chart"
					v-show="ready"
					width="100%"
					height="100%"
					:type="options.chart.type"
					:options="options"
					:series="series"
				/>
			</div>
			<div
				v-if="config.legend && mobileShowCheck"
				class="custom-legend"
				:class="legendClass || {}"
			>
				<template v-for="(item, index) in seriesConfig">
					<div
						v-if="!skip[item.value]"
						:key="index"
						class="custom-legend-item"
						:class="{ collapsed: collapsed[item.label], hidden: item.hidden }"
						@click="toggleSerie(item.label)"
					>
						<span
							class="custom-legend-marker"
							:style="{ 'background-color': item.color }"
						/>
						<span class="custom-legend-text" v-text="item.label" />
					</div>
				</template>
			</div>
		</center-display>
	</div>
</template>

<style lang="scss">
.dark-bg {
	.custom-legend .custom-legend-item .custom-legend-text {
		color: #ffffff;
	}
}

.custom-legend {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin-top: -5px;
	padding-bottom: 10px;

	.custom-legend-item {
		display: flex;
		align-items: center;
		padding: 10px 5px 0 5px;
		cursor: pointer;
		transition: 0.15s;
		line-height: normal;

		&.hidden {
			display: none;
			opacity: 0;
		}

		&.collapsed {
			opacity: 0.4;
		}

		.custom-legend-text {
			position: relative;
			font-size: 12px;
			font-weight: 400;
			font-family: Helvetica, Arial, sans-serif;
			color: #262626;
		}

		.custom-legend-marker {
			position: relative;
			display: inline-block;
			height: 12px;
			width: 12px;
			border-radius: 50%;
			margin-right: 5px;
		}
	}
}
</style>

<script>
import moment from 'moment'
import NumberFormatter from '../../utils/NumberFormatter'

import CenterDisplay from '../CenterDisplay'
import ApexDefaultOptions from '../../constants/apex'
import deepmerge from 'deepmerge'
import path from 'ramda/src/path'

export default {
	name: 'LineGraph',
	props: ['id', 'config', 'data', 'updatedOptions', 'graphClass', 'graphStyle', 'legendClass'],
	components: { CenterDisplay },
	data() {
		return {
			mobile: false,
			init: true,
			ready: false,
			series: [],
			skip: {},
			collapsed: {}
		}
	},
	created() {
		this.format = this.config.format || {}

		this.mobile = window.innerWidth < 800

		this.options = this.processOptions(path(['config', 'options'], this))

		for (const value in this.seriesConfig) {
			this.skip[value] = true
		}
	},
	mounted() {
		this.updateData(this.data)
		this.$nextTick(() => {
			this.ready = true
		})
	},
	watch: {
		config({ options }) {
			this.options = this.processOptions(options)
		},
		updatedOptions(options) {
			this.$refs.chart.updateOptions(options)
		},
		data(data) {
			this.updateData(data)
		}
	},
	computed: {
		mobileShowCheck() {
			if (!this.mobile) return true
			return this.series.length <= 3
		},
		seriesConfig() {
			return this.config.series
				? this.config.series.reduce((acc, e) => ({ ...acc, [e.value]: e }), {})
				: {}
		}
	},
	methods: {
		processOptions(options) {
			const newOptions = deepmerge.all([
				ApexDefaultOptions,
				{
					stroke: {
						width: 2
					},
					xaxis: {
						type: 'datetime',
						labels: {
							datetimeUTC: false,
							datetimeFormatter: {
								year: 'yyyy',
								month: "MMM 'yy",
								day: "d MMM 'yy",
								hour: 'HH:mm'
							}
						}
					},
					legend: {
						show: false
					},
					chart: {
						type: 'line',
						animations: {
							enabled: false
						},
						toolbar: {
							show: true
						},
						events: {
							// Only used on the native legend approach
							legendClick: (ctx, seriesIdx) => {
								const name = this.series[seriesIdx].name
								this.collapsed[name] = !this.collapsed[name]

								// Hide or show the toggled series
								if (!this.collapsed[name]) {
									this.$refs.chart.showSeries(name)
								} else {
									this.$refs.chart.hideSeries(name)
								}
							},
							zoomed: (chartContext, { xaxis }) => {
								this.$emit('on-zoom', {
									from: moment(xaxis.min),
									to: moment(xaxis.max),
									selectedRange: 'custom'
								})
							}
						}
					}
				},
				options || {}
			])

			if (this.mobile) {
				newOptions.chart.toolbar.show = false
				newOptions.legend.show = false
			}

			if (Array.isArray(newOptions.yaxis)) {
				for (const index in newOptions.yaxis) {
					if (!newOptions.yaxis[index].labels) newOptions.yaxis[index].labels = {}
					const formatter = new NumberFormatter(
						this.$n.bind(this),
						newOptions.yaxis[index].format || this.format
					)
					newOptions.yaxis[index].labels.formatter = formatter.getter()
				}
			} else {
				const formatter = new NumberFormatter(this.$n.bind(this), this.format)
				newOptions.yaxis.labels.formatter = formatter.getter()
			}

			return newOptions
		},
		toggleSerie(id, state) {
			this.$set(this.collapsed, id, state == undefined ? !this.collapsed[id] : state)
			const series = JSON.parse(JSON.stringify(this.series))
			for (const name in this.data || this.seriesConfig) {
				const cfg = this.seriesConfig[name]
				if (!cfg) continue

				const collapsed = this.collapsed[cfg.label]
				for (const item of series) {
					if (item.name !== cfg.label) continue
					item.color = collapsed ? 'rgba(0, 0, 0, 0)' : cfg.color
					break
				}
			}
			this.series = series
		},
		updateData(results) {
			if (results === undefined) return
			const series = []

			for (const value in this.seriesConfig) {
				this.skip[value] = true
			}

			for (const value in results) {
				const cfg = this.seriesConfig[value]
				if (!cfg) continue

				const collapsed = this.init ? cfg.collapsed : this.collapsed[cfg.label]
				const data = Array.isArray(results[value])
					? results[value]
					: Object.keys(results[value]).map(x => {
							return [x, results[value][x]]
					  })

				if (this.init) {
					this.$set(this.collapsed, cfg.label, collapsed)
				}

				if (data.length > 0) {
					this.skip[value] = false
				}

				series.push({
					name: cfg.label,
					format: cfg.format,
					type: cfg.type,
					// If custom legend is enabled, set colour to transparent when toggled
					color: this.config.legend && collapsed ? 'rgba(0, 0, 0, 0)' : cfg.color,
					collapsed,
					data
				})
			}

			this.init = false
			this.series = series

			// If custom legend is NOT enabled and the native one is enabled
			if (!this.config.legend && this.options.legend.show) {
				this.$nextTick(() => {
					for (const name in this.collapsed) {
						if (this.collapsed[name]) continue
						this.toggleSerie(name, true)
					}
				})
			}
		}
	}
}
</script>
