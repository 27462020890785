import se from '../../api-clients/se'

const FETCH = 'FETCH'
const FETCH_START = 'FETCH_START'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_ERROR = 'FETCH_ERROR'
const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

const state = {
	// Current State
	fetching: false,
	error: null,

	// Data
	id: null,
	data: {}
}

const actions = {
	async [FETCH]({ commit }, { id }) {
		commit(FETCH_START)

		try {
			const data = await se.assets.getAssetById({ id })

			commit(FETCH_SUCCESS, { id, data: JSON.parse(JSON.stringify(data)) })
		} catch (error) {
			commit(FETCH_ERROR, error)
		}
	},
	[UPDATE_SETTINGS]({ commit }, { project, cut }) {
		commit(UPDATE_SETTINGS, { project, cut })
	}
}

const mutations = {
	[FETCH_START](state) {
		state.fetching = true
	},
	[FETCH_SUCCESS](state, { id, data }) {
		state.fetching = false
		state.error = null
		state.id = id
		state.data = data
	},
	[UPDATE_SETTINGS](state, { project, cut }) {
		state.data.project = project
		state.data.cut.value = cut
	}
}

class User {
	constructor() {
		this.namespaced = true
		this.state = state
		this.actions = actions
		this.mutations = mutations
	}
}

export default User
