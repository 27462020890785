import Vue from 'vue'
import App from './pages/App'
import VueApexCharts from 'vue-apexcharts'
import VueI18n from 'vue-i18n'
import vuetify from './plugins/vuetify'
import DateRangePicker from 'vue2-daterange-picker'
import { messages, numberFormats } from './content/translations'
import './sass/index.scss'
import 'leaflet/dist/leaflet.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import store from './store'
import router from './router'

Vue.config.productionTip = false
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'nl',
	fallbackLocale: 'nl',
	messages,
	numberFormats
})

new Vue({
	i18n,
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
