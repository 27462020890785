// TODO: This is a mock data file which is needed to calculate example data (to date)
export default {
	'0': {
		name: 'januari',
		modifier: 0.04,
		days: 31
	},
	'1': {
		name: 'februari',
		modifier: 0.06,
		days: 28
	},
	'2': {
		name: 'maart',
		modifier: 0.08,
		days: 31
	},
	'3': {
		name: 'april',
		modifier: 0.1,
		days: 30
	},
	'4': {
		name: 'mei',
		modifier: 0.12,
		days: 31
	},
	'5': {
		name: 'juni',
		modifier: 0.14,
		days: 30
	},
	'6': {
		name: 'juli',
		modifier: 0.135,
		days: 31
	},
	'7': {
		name: 'augustus',
		modifier: 0.115,
		days: 31
	},
	'8': {
		name: 'september',
		modifier: 0.08,
		days: 30
	},
	'9': {
		name: 'oktober',
		modifier: 0.06,
		days: 31
	},
	'10': {
		name: 'november',
		modifier: 0.04,
		days: 30
	},
	'11': {
		name: 'december',
		modifier: 0.03,
		days: 31
	}
}
