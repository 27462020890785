// TODO: This is a temporary storage location for all assets
export default {
	'0': {
		location: {
			name: 'Rustenburgerstraat 15, Amsterdam',
			latitude: 52.35378,
			longitude: 4.90577
		},
		startDate: '4 januari 2021',
		capacity: '230 kWpiek',
		reimbursementStructure: 'Subsidieregeling Coöperatieve Energieopwekking',
		cut: {
			value: 0.03,
			suffix: '(per kWh)'
		},
		// TODO: In an actual version projects should be separate from assets
		project: {
			name: 'Wijkcentrum de Pijp',
			href: 'https://www.wijkcentrumdepijp.nl/',
			description: `Actief wijkcentrum met verschillende duurzame initiatieven, zoals een Groen Gemaal.`,
			location: {
				latitude: 52.35027,
				longitude: 4.89439
			},
			total: 1838.4,
			goal: 5000
		},
		data: {
			day: {
				contribution: 6.32,
				production: 25,
				graph: {
					pvPanels: {
						'2020-12-07T23:00:00+00:00': 24.6570148,
						'2020-12-07T23:15:00+00:00': 36.94796712,
						'2020-12-07T23:30:00+00:00': 29.064699,
						'2020-12-07T23:45:00+00:00': 24.37676773,
						'2020-12-08T00:00:00+00:00': 25.97448195,
						'2020-12-08T00:15:00+00:00': 22.41785474,
						'2020-12-08T00:30:00+00:00': 30.3549269,
						'2020-12-08T00:45:00+00:00': 35.70432783,
						'2020-12-08T01:00:00+00:00': 23.40502575,
						'2020-12-08T01:15:00+00:00': 26.47447689,
						'2020-12-08T01:30:00+00:00': 33.77937477,
						'2020-12-08T01:45:00+00:00': 31.82798797,
						'2020-12-08T02:00:00+00:00': 35.11035104,
						'2020-12-08T02:15:00+00:00': 23.93397217,
						'2020-12-08T02:30:00+00:00': 26.16776321,
						'2020-12-08T02:45:00+00:00': 27.92986576,
						'2020-12-08T03:00:00+00:00': 34.70658883,
						'2020-12-08T03:15:00+00:00': 29.63608076,
						'2020-12-08T03:30:00+00:00': 28.53766597,
						'2020-12-08T03:45:00+00:00': 27.66163612,
						'2020-12-08T04:00:00+00:00': 25.11948252,
						'2020-12-08T04:15:00+00:00': 35.42916573,
						'2020-12-08T04:30:00+00:00': 67.14576894,
						'2020-12-08T04:45:00+00:00': 79.46089106,
						'2020-12-08T05:00:00+00:00': 31.43750092,
						'2020-12-08T05:15:00+00:00': 25.10157327,
						'2020-12-08T05:30:00+00:00': 25.54671871,
						'2020-12-08T05:45:00+00:00': 28.01616588,
						'2020-12-08T06:00:00+00:00': 55.76521187,
						'2020-12-08T06:15:00+00:00': 76.29116988,
						'2020-12-08T06:30:00+00:00': 74.55935655,
						'2020-12-08T06:45:00+00:00': 81.25527062,
						'2020-12-08T07:00:00+00:00': 69.72556724,
						'2020-12-08T07:15:00+00:00': 38.96130946,
						'2020-12-08T07:30:00+00:00': 48.31448457,
						'2020-12-08T07:45:00+00:00': 37.77208272,
						'2020-12-08T08:00:00+00:00': 90.84040755,
						'2020-12-08T08:15:00+00:00': 179.8374898,
						'2020-12-08T08:30:00+00:00': 148.3529223,
						'2020-12-08T08:45:00+00:00': 55.14012733,
						'2020-12-08T09:00:00+00:00': 85.39279973,
						'2020-12-08T09:15:00+00:00': 75.07352893,
						'2020-12-08T09:30:00+00:00': 76.01478331,
						'2020-12-08T09:45:00+00:00': 76.75603659,
						'2020-12-08T10:00:00+00:00': 62.60922854,
						'2020-12-08T10:15:00+00:00': 19.76244046,
						'2020-12-08T10:30:00+00:00': 18.3760117,
						'2020-12-08T10:45:00+00:00': 20.57057909,
						'2020-12-08T11:00:00+00:00': 22.18076045,
						'2020-12-08T11:15:00+00:00': 18.15489411,
						'2020-12-08T11:30:00+00:00': 28.31625639,
						'2020-12-08T11:45:00+00:00': 19.23201691,
						'2020-12-08T12:00:00+00:00': 15.69365967,
						'2020-12-08T12:15:00+00:00': 20.35501428,
						'2020-12-08T12:30:00+00:00': 21.44413488,
						'2020-12-08T12:45:00+00:00': 29.57610041,
						'2020-12-08T13:00:00+00:00': 18.54844469,
						'2020-12-08T13:15:00+00:00': 11.09293668,
						'2020-12-08T13:30:00+00:00': 16.35462933,
						'2020-12-08T13:45:00+00:00': 15.82016108,
						'2020-12-08T14:00:00+00:00': 34.68323624,
						'2020-12-08T14:15:00+00:00': 14.63567535,
						'2020-12-08T14:30:00+00:00': 22.55753616,
						'2020-12-08T14:45:00+00:00': 23.71621683,
						'2020-12-08T15:00:00+00:00': 27.51048505,
						'2020-12-08T15:15:00+00:00': 18.02211993,
						'2020-12-08T15:30:00+00:00': 14.37267771,
						'2020-12-08T15:45:00+00:00': 19.81993955,
						'2020-12-08T16:00:00+00:00': 23.03639941,
						'2020-12-08T16:15:00+00:00': 40.1380239,
						'2020-12-08T16:30:00+00:00': 17.94109563,
						'2020-12-08T16:45:00+00:00': 24.33026975,
						'2020-12-08T17:00:00+00:00': 25.41132582,
						'2020-12-08T17:15:00+00:00': 27.97547321,
						'2020-12-08T17:30:00+00:00': 30.78912727,
						'2020-12-08T17:45:00+00:00': 15.33591342,
						'2020-12-08T18:00:00+00:00': 51.80053788,
						'2020-12-08T18:15:00+00:00': 56.39170739,
						'2020-12-08T18:30:00+00:00': 65.95558335,
						'2020-12-08T18:45:00+00:00': 81.43037101,
						'2020-12-08T19:00:00+00:00': 142.6443506,
						'2020-12-08T19:15:00+00:00': 94.0984766,
						'2020-12-08T19:30:00+00:00': 85.83787838,
						'2020-12-08T19:45:00+00:00': 19.01420699,
						'2020-12-08T20:00:00+00:00': 24.21178714,
						'2020-12-08T20:15:00+00:00': 14.54375665,
						'2020-12-08T20:30:00+00:00': 14.18280099,
						'2020-12-08T20:45:00+00:00': 18.34894444,
						'2020-12-08T21:00:00+00:00': 36.62606797,
						'2020-12-08T21:15:00+00:00': 22.23901931,
						'2020-12-08T21:30:00+00:00': 18.55923879,
						'2020-12-08T21:45:00+00:00': 17.59937778,
						'2020-12-08T22:00:00+00:00': 20.26060492,
						'2020-12-08T22:15:00+00:00': 32.50607495,
						'2020-12-08T22:30:00+00:00': 13.79532104,
						'2020-12-08T22:45:00+00:00': 14.01699399
					}
				}
			},
			_yearTotalProduction: 218500,
			totalProduction: '61.280 kWh',
			contribution: '€ 1.838,40',
			expectedYearContribution: '€ 6.693,00'
		}
	},
	'1': {
		location: {
			name: 'Plantage Muidergracht 12, Amsterdam',
			latitude: 52.36516,
			longitude: 4.91165
		},
		startDate: '4 januari 2021',
		capacity: '450 kWpiek',
		reimbursementStructure: 'SDE+',
		cut: {
			value: 1500,
			min: 500,
			max: 4000,
			suffix: '(per maand)'
		},
		project: {
			name: 'Denktank Schone Start',
			href: 'https://academievandestad.nl/projecten/denktank-schone-start/',
			description: `Een denktank bestaande uit jonge, creatieve studenten en recent afgestudeerden die werken aan oplossingen voor verschillende maatschappelijke uitdagingen.`,
			location: {
				latitude: 52.36758,
				longitude: 4.88741
			},
			total: 4500,
			goal: 8000
		},
		data: {
			day: {
				contribution: 1.9 * 6.32,
				production: 1.9 * 25,
				graph: {
					pvPanels: {
						'2020-12-07T23:00:00+00:00': 1.8967 * 24.6570148,
						'2020-12-07T23:15:00+00:00': 1.0415 * 36.94796712,
						'2020-12-07T23:30:00+00:00': 1.0811 * 29.064699,
						'2020-12-07T23:45:00+00:00': 1.9665 * 24.37676773,
						'2020-12-08T00:00:00+00:00': 1.1826 * 25.97448195,
						'2020-12-08T00:15:00+00:00': 1.6108 * 22.41785474,
						'2020-12-08T00:30:00+00:00': 1.7958 * 30.3549269,
						'2020-12-08T00:45:00+00:00': 1.5839 * 35.70432783,
						'2020-12-08T01:00:00+00:00': 1.4006 * 23.40502575,
						'2020-12-08T01:15:00+00:00': 1.2607 * 26.47447689,
						'2020-12-08T01:30:00+00:00': 1.9171 * 33.77937477,
						'2020-12-08T01:45:00+00:00': 1.5254 * 31.82798797,
						'2020-12-08T02:00:00+00:00': 1.8253 * 35.11035104,
						'2020-12-08T02:15:00+00:00': 1.5635 * 23.93397217,
						'2020-12-08T02:30:00+00:00': 1.7448 * 26.16776321,
						'2020-12-08T02:45:00+00:00': 1.5909 * 27.92986576,
						'2020-12-08T03:00:00+00:00': 1.4173 * 34.70658883,
						'2020-12-08T03:15:00+00:00': 1.1578 * 29.63608076,
						'2020-12-08T03:30:00+00:00': 1.7128 * 28.53766597,
						'2020-12-08T03:45:00+00:00': 1.4857 * 27.66163612,
						'2020-12-08T04:00:00+00:00': 1.058 * 25.11948252,
						'2020-12-08T04:15:00+00:00': 1.2505 * 35.42916573,
						'2020-12-08T04:30:00+00:00': 1.1522 * 67.14576894,
						'2020-12-08T04:45:00+00:00': 1.5727 * 79.46089106,
						'2020-12-08T05:00:00+00:00': 1.4271 * 31.43750092,
						'2020-12-08T05:15:00+00:00': 1.9719 * 25.10157327,
						'2020-12-08T05:30:00+00:00': 1.6261 * 25.54671871,
						'2020-12-08T05:45:00+00:00': 1.0292 * 28.01616588,
						'2020-12-08T06:00:00+00:00': 1.4024 * 55.76521187,
						'2020-12-08T06:15:00+00:00': 1.3199 * 76.29116988,
						'2020-12-08T06:30:00+00:00': 1.1143 * 74.55935655,
						'2020-12-08T06:45:00+00:00': 1.0086 * 81.25527062,
						'2020-12-08T07:00:00+00:00': 1.6355 * 69.72556724,
						'2020-12-08T07:15:00+00:00': 1.1189 * 38.96130946,
						'2020-12-08T07:30:00+00:00': 1.9793 * 48.31448457,
						'2020-12-08T07:45:00+00:00': 1.5665 * 37.77208272,
						'2020-12-08T08:00:00+00:00': 1.5145 * 90.84040755,
						'2020-12-08T08:15:00+00:00': 1.9391 * 179.8374898,
						'2020-12-08T08:30:00+00:00': 1.9568 * 148.3529223,
						'2020-12-08T08:45:00+00:00': 1.0419 * 55.14012733,
						'2020-12-08T09:00:00+00:00': 1.2644 * 85.39279973,
						'2020-12-08T09:15:00+00:00': 1.8835 * 75.07352893,
						'2020-12-08T09:30:00+00:00': 1.6043 * 76.01478331,
						'2020-12-08T09:45:00+00:00': 1.1607 * 76.75603659,
						'2020-12-08T10:00:00+00:00': 1.4876 * 62.60922854,
						'2020-12-08T10:15:00+00:00': 1.072 * 19.76244046,
						'2020-12-08T10:30:00+00:00': 1.3106 * 18.3760117,
						'2020-12-08T10:45:00+00:00': 1.312 * 20.57057909,
						'2020-12-08T11:00:00+00:00': 1.1719 * 22.18076045,
						'2020-12-08T11:15:00+00:00': 1.7237 * 18.15489411,
						'2020-12-08T11:30:00+00:00': 1.7212 * 28.31625639,
						'2020-12-08T11:45:00+00:00': 1.3264 * 19.23201691,
						'2020-12-08T12:00:00+00:00': 1.0092 * 15.69365967,
						'2020-12-08T12:15:00+00:00': 1.4209 * 20.35501428,
						'2020-12-08T12:30:00+00:00': 1.094 * 21.44413488,
						'2020-12-08T12:45:00+00:00': 1.1412 * 29.57610041,
						'2020-12-08T13:00:00+00:00': 1.5785 * 18.54844469,
						'2020-12-08T13:15:00+00:00': 1.6518 * 11.09293668,
						'2020-12-08T13:30:00+00:00': 1.7766 * 16.35462933,
						'2020-12-08T13:45:00+00:00': 1.4573 * 15.82016108,
						'2020-12-08T14:00:00+00:00': 1.3548 * 34.68323624,
						'2020-12-08T14:15:00+00:00': 1.6576 * 14.63567535,
						'2020-12-08T14:30:00+00:00': 1.6245 * 22.55753616,
						'2020-12-08T14:45:00+00:00': 1.8691 * 23.71621683,
						'2020-12-08T15:00:00+00:00': 1.4034 * 27.51048505,
						'2020-12-08T15:15:00+00:00': 1.8277 * 18.02211993,
						'2020-12-08T15:30:00+00:00': 1.71 * 14.37267771,
						'2020-12-08T15:45:00+00:00': 1.363 * 19.81993955,
						'2020-12-08T16:00:00+00:00': 1.7688 * 23.03639941,
						'2020-12-08T16:15:00+00:00': 1.3362 * 40.1380239,
						'2020-12-08T16:30:00+00:00': 1.9405 * 17.94109563,
						'2020-12-08T16:45:00+00:00': 1.7059 * 24.33026975,
						'2020-12-08T17:00:00+00:00': 1.1404 * 25.41132582,
						'2020-12-08T17:15:00+00:00': 1.4998 * 27.97547321,
						'2020-12-08T17:30:00+00:00': 1.9152 * 30.78912727,
						'2020-12-08T17:45:00+00:00': 1.4078 * 15.33591342,
						'2020-12-08T18:00:00+00:00': 1.4894 * 51.80053788,
						'2020-12-08T18:15:00+00:00': 1.4166 * 56.39170739,
						'2020-12-08T18:30:00+00:00': 1.1282 * 65.95558335,
						'2020-12-08T18:45:00+00:00': 1.7443 * 81.43037101,
						'2020-12-08T19:00:00+00:00': 1.8231 * 142.6443506,
						'2020-12-08T19:15:00+00:00': 1.5825 * 94.0984766,
						'2020-12-08T19:30:00+00:00': 1.4944 * 85.83787838,
						'2020-12-08T19:45:00+00:00': 1.464 * 19.01420699,
						'2020-12-08T20:00:00+00:00': 1.7066 * 24.21178714,
						'2020-12-08T20:15:00+00:00': 1.5399 * 14.54375665,
						'2020-12-08T20:30:00+00:00': 1.5768 * 14.18280099,
						'2020-12-08T20:45:00+00:00': 1.4807 * 18.34894444,
						'2020-12-08T21:00:00+00:00': 1.3061 * 36.62606797,
						'2020-12-08T21:15:00+00:00': 1.1946 * 22.23901931,
						'2020-12-08T21:30:00+00:00': 1.5602 * 18.55923879,
						'2020-12-08T21:45:00+00:00': 1.4925 * 17.59937778,
						'2020-12-08T22:00:00+00:00': 1.4533 * 20.26060492,
						'2020-12-08T22:15:00+00:00': 1.6016 * 32.50607495,
						'2020-12-08T22:30:00+00:00': 1.3847 * 13.79532104,
						'2020-12-08T22:45:00+00:00': 1.8135 * 14.01699399
					}
				}
			},
			_yearTotalProduction: 427500,
			totalProduction: '106.875 kWh',
			contribution: '€ 4.500,00',
			expectedYearContribution: '€ 18.000,00'
		}
	},
	'2': {
		location: {
			name: 'Darlingstraat 1, Amsterdam',
			latitude: 52.31977,
			longitude: 4.94979
		},
		startDate: '4 januari 2021',
		capacity: '35 kWpiek',
		reimbursementStructure: 'Salderingsregeling',
		cut: {
			value: 85,
			min: 50,
			max: 300,
			suffix: '(per maand)'
		},
		project: {
			name: 'Gaasperdam SLIM naar QuickFit Aardgasvrij',
			href: 'https://www.cocratos.nl/slimwonengaasperdam/',
			description: `Initiatief van een groep bewoners om op een circulaire en eerlijke manier aardgasvrij te worden, op basis van Groen Gas uit ontlasting icm QuickFit maatregelen aan de woningen.`,
			location: {
				latitude: 52.2953306,
				longitude: 4.9700638
			},
			total: 255,
			goal: 2500
		},
		data: {
			day: {
				contribution: 0.15 * 6.32,
				production: 0.15 * 25,
				graph: {
					pvPanels: {
						'2020-12-07T23:00:00+00:00': 0.296 * 24.6570148,
						'2020-12-07T23:15:00+00:00': 0.175 * 36.94796712,
						'2020-12-07T23:30:00+00:00': 0.108 * 29.064699,
						'2020-12-07T23:45:00+00:00': 0.143 * 24.37676773,
						'2020-12-08T00:00:00+00:00': 0.197 * 25.97448195,
						'2020-12-08T00:15:00+00:00': 0.149 * 22.41785474,
						'2020-12-08T00:30:00+00:00': 0.228 * 30.3549269,
						'2020-12-08T00:45:00+00:00': 0.179 * 35.70432783,
						'2020-12-08T01:00:00+00:00': 0.117 * 23.40502575,
						'2020-12-08T01:15:00+00:00': 0.109 * 26.47447689,
						'2020-12-08T01:30:00+00:00': 0.285 * 33.77937477,
						'2020-12-08T01:45:00+00:00': 0.201 * 31.82798797,
						'2020-12-08T02:00:00+00:00': 0.293 * 35.11035104,
						'2020-12-08T02:15:00+00:00': 0.179 * 23.93397217,
						'2020-12-08T02:30:00+00:00': 0.104 * 26.16776321,
						'2020-12-08T02:45:00+00:00': 0.291 * 27.92986576,
						'2020-12-08T03:00:00+00:00': 0.242 * 34.70658883,
						'2020-12-08T03:15:00+00:00': 0.281 * 29.63608076,
						'2020-12-08T03:30:00+00:00': 0.16 * 28.53766597,
						'2020-12-08T03:45:00+00:00': 0.175 * 27.66163612,
						'2020-12-08T04:00:00+00:00': 0.16 * 25.11948252,
						'2020-12-08T04:15:00+00:00': 0.108 * 35.42916573,
						'2020-12-08T04:30:00+00:00': 0.245 * 67.14576894,
						'2020-12-08T04:45:00+00:00': 0.296 * 79.46089106,
						'2020-12-08T05:00:00+00:00': 0.134 * 31.43750092,
						'2020-12-08T05:15:00+00:00': 0.2 * 25.10157327,
						'2020-12-08T05:30:00+00:00': 0.108 * 25.54671871,
						'2020-12-08T05:45:00+00:00': 0.222 * 28.01616588,
						'2020-12-08T06:00:00+00:00': 0.188 * 55.76521187,
						'2020-12-08T06:15:00+00:00': 0.194 * 76.29116988,
						'2020-12-08T06:30:00+00:00': 0.184 * 74.55935655,
						'2020-12-08T06:45:00+00:00': 0.203 * 81.25527062,
						'2020-12-08T07:00:00+00:00': 0.226 * 69.72556724,
						'2020-12-08T07:15:00+00:00': 0.297 * 38.96130946,
						'2020-12-08T07:30:00+00:00': 0.146 * 48.31448457,
						'2020-12-08T07:45:00+00:00': 0.215 * 37.77208272,
						'2020-12-08T08:00:00+00:00': 0.21 * 90.84040755,
						'2020-12-08T08:15:00+00:00': 0.14 * 179.8374898,
						'2020-12-08T08:30:00+00:00': 0.25 * 148.3529223,
						'2020-12-08T08:45:00+00:00': 0.184 * 55.14012733,
						'2020-12-08T09:00:00+00:00': 0.193 * 85.39279973,
						'2020-12-08T09:15:00+00:00': 0.233 * 75.07352893,
						'2020-12-08T09:30:00+00:00': 0.158 * 76.01478331,
						'2020-12-08T09:45:00+00:00': 0.201 * 76.75603659,
						'2020-12-08T10:00:00+00:00': 0.26 * 62.60922854,
						'2020-12-08T10:15:00+00:00': 0.263 * 19.76244046,
						'2020-12-08T10:30:00+00:00': 0.158 * 18.3760117,
						'2020-12-08T10:45:00+00:00': 0.205 * 20.57057909,
						'2020-12-08T11:00:00+00:00': 0.246 * 22.18076045,
						'2020-12-08T11:15:00+00:00': 0.162 * 18.15489411,
						'2020-12-08T11:30:00+00:00': 0.18 * 28.31625639,
						'2020-12-08T11:45:00+00:00': 0.152 * 19.23201691,
						'2020-12-08T12:00:00+00:00': 0.294 * 15.69365967,
						'2020-12-08T12:15:00+00:00': 0.23 * 20.35501428,
						'2020-12-08T12:30:00+00:00': 0.232 * 21.44413488,
						'2020-12-08T12:45:00+00:00': 0.204 * 29.57610041,
						'2020-12-08T13:00:00+00:00': 0.279 * 18.54844469,
						'2020-12-08T13:15:00+00:00': 0.283 * 11.09293668,
						'2020-12-08T13:30:00+00:00': 0.121 * 16.35462933,
						'2020-12-08T13:45:00+00:00': 0.128 * 15.82016108,
						'2020-12-08T14:00:00+00:00': 0.133 * 34.68323624,
						'2020-12-08T14:15:00+00:00': 0.142 * 14.63567535,
						'2020-12-08T14:30:00+00:00': 0.212 * 22.55753616,
						'2020-12-08T14:45:00+00:00': 0.135 * 23.71621683,
						'2020-12-08T15:00:00+00:00': 0.131 * 27.51048505,
						'2020-12-08T15:15:00+00:00': 0.289 * 18.02211993,
						'2020-12-08T15:30:00+00:00': 0.152 * 14.37267771,
						'2020-12-08T15:45:00+00:00': 0.206 * 19.81993955,
						'2020-12-08T16:00:00+00:00': 0.242 * 23.03639941,
						'2020-12-08T16:15:00+00:00': 0.189 * 40.1380239,
						'2020-12-08T16:30:00+00:00': 0.22 * 17.94109563,
						'2020-12-08T16:45:00+00:00': 0.117 * 24.33026975,
						'2020-12-08T17:00:00+00:00': 0.258 * 25.41132582,
						'2020-12-08T17:15:00+00:00': 0.282 * 27.97547321,
						'2020-12-08T17:30:00+00:00': 0.178 * 30.78912727,
						'2020-12-08T17:45:00+00:00': 0.18 * 15.33591342,
						'2020-12-08T18:00:00+00:00': 0.218 * 51.80053788,
						'2020-12-08T18:15:00+00:00': 0.156 * 56.39170739,
						'2020-12-08T18:30:00+00:00': 0.144 * 65.95558335,
						'2020-12-08T18:45:00+00:00': 0.175 * 81.43037101,
						'2020-12-08T19:00:00+00:00': 0.141 * 142.6443506,
						'2020-12-08T19:15:00+00:00': 0.156 * 94.0984766,
						'2020-12-08T19:30:00+00:00': 0.281 * 85.83787838,
						'2020-12-08T19:45:00+00:00': 0.124 * 19.01420699,
						'2020-12-08T20:00:00+00:00': 0.26 * 24.21178714,
						'2020-12-08T20:15:00+00:00': 0.269 * 14.54375665,
						'2020-12-08T20:30:00+00:00': 0.233 * 14.18280099,
						'2020-12-08T20:45:00+00:00': 0.107 * 18.34894444,
						'2020-12-08T21:00:00+00:00': 0.221 * 36.62606797,
						'2020-12-08T21:15:00+00:00': 0.23 * 22.23901931,
						'2020-12-08T21:30:00+00:00': 0.147 * 18.55923879,
						'2020-12-08T21:45:00+00:00': 0.278 * 17.59937778,
						'2020-12-08T22:00:00+00:00': 0.1 * 20.26060492,
						'2020-12-08T22:15:00+00:00': 0.254 * 32.50607495,
						'2020-12-08T22:30:00+00:00': 0.267 * 13.79532104,
						'2020-12-08T22:45:00+00:00': 0.214 * 14.01699399
					}
				}
			},
			_yearTotalProduction: 33250,
			totalProduction: '8.312 kWh',
			contribution: '€ 255,00',
			expectedYearContribution: '€ 1.020,00'
		}
	}
}
