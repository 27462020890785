<template>
	<v-app class="custom-background">
		<top-header :routes="$router.options.routes" :user="user.data" :asset="asset" />
		<div class="pb-5" />
		<v-main class="grey lighten-4 mt-15 pb-5">
			<transition name="fade" mode="out-in">
				<router-view />
			</transition>
		</v-main>
	</v-app>
</template>

<script>
// Utils
import { initBasicStore } from './store'
import { mapState } from 'vuex'

// Components
import TopHeader from '../../components/TopHeader'

export default {
	name: 'App',
	components: {
		TopHeader
	},
	computed: {
		...mapState(['user', 'asset'])
	},
	beforeCreate() {
		initBasicStore()
	}
}
</script>
