<template>
	<v-container>
		<v-row class="d-flex flex-nowrap">
			<tile title="Map" icon="mdi-map">
				<v-row>
					<v-col>
						<leaflet-map
							class="rounded"
							:style="{ 'z-index': 0 }"
							:height="350"
							:zoom="11.5"
							:center="{ latitude: 52.337189, longitude: 4.915431 }"
							:markers="markers"
						/>
					</v-col>
				</v-row>
			</tile>
			<tile title="Contributie verdeling per project" class="col-4">
				<pie
					class="d-flex align-center justify-center"
					:config="config.contribution"
					:data="contributionProjectData"
				/>
			</tile>
		</v-row>
		<!-- TODO: Maybe reconsider adding this back if there are more projects -->
		<!-- <v-row class="d-flex flex-nowrap">
			<tile title="Contributies per project">
				<treemap
					:style="{ height: '300px', 'margin-right': '-125px', 'margin-top': '-220px' }"
					:config="config.treemap"
					:data="contributionProjectItems"
				/>
			</tile>
		</v-row> -->
		<v-row class="d-flex flex-nowrap">
			<tile title="Totale contributie">
				<h1 class="d-inline text--gradient--green">
					{{ $n(totalProduction * 0.035, 'currency') }}
				</h1>
			</tile>
			<tile title="Totale opwek">
				<h1 class="d-inline text--gradient--green">{{ formatter.get(totalProduction) }}</h1>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile title="Projecten">
				<v-data-table
					:headers="projects.headers"
					:items="projectItems"
					:items-per-page="-1"
					class="elevation-0"
				>
					<template v-slot:item.project="{ item }">
						<v-btn
							:style="{ margin: '0 -5px' }"
							color="primary"
							text
							small
							target="_blank"
							:href="item.href"
						>
							{{ item.name }}
							<v-icon class="ml-2" v-text="'mdi-link'" />
						</v-btn>
					</template>
					<template v-slot:item.percentage="{ item }">
						<v-progress-linear
							color="light-green"
							rounded
							height="6"
							:value="
								((getProduction(item.yearTotalProduction) * 0.035) / item.goal) *
									100
							"
						/>
					</template>
					<template v-slot:item.total="{ item }">
						{{ $n(getProduction(item.yearTotalProduction) * 0.035, 'currency') }}
					</template>
					<template v-slot:item.goal="{ item }">
						{{ $n(item.goal, 'currency') }}
					</template>
				</v-data-table>
			</tile>
		</v-row>
	</v-container>
</template>

<script>
// Utils
import NumberFormatter from '../../utils/NumberFormatter'

// Data
import assets from '../../constants/_assets'
import monthModifier from '../../constants/_monthModifier'

// Components
import Tile from '../../components/Tile'
import LeafletMap from '../../components/LeafletMap'
import { Pie } from '../../components/Pies'

export default {
	name: 'Overview',
	components: { Tile, LeafletMap, Pie },
	data: () => ({
		config: {
			treemap: {
				options: {
					colors: ['#A2CD3A']
				},
				format: {
					formatter: 'currency'
				}
			},
			contribution: {
				format: {
					hidden: true,
					formatter: 'currency'
				},
				options: {
					plotOptions: {
						pie: {
							customScale: 0.9,
							donut: {
								size: '0%'
							}
						}
					},
					legend: {
						position: 'bottom',
						width: null
					}
				},
				series: [
					{
						color: '#2b87ba',
						label: 'Wijkcentrum de Pijp',
						value: '0'
					},
					{
						color: '#45a0d4',
						label: 'Denktank Schone Start',
						value: '1'
					},
					{
						color: '#6eb5de',
						label: 'Gaasperdam SLIM naar QuickFit Aardgasvrij',
						value: '2'
					}
				]
			}
		},
		projects: {
			items: [
				{
					project: {
						name: 'Wijkcentrum de Pijp',
						href: 'https://www.wijkcentrumdepijp.nl/'
					},
					total: 712.17,
					goal: 5000
				},
				{
					project: {
						name: 'Denktank Schone Start',
						href: 'https://academievandestad.nl/projecten/denktank-schone-start/'
					},
					total: 4500,
					goal: 8000
				},
				{
					project: {
						name: 'Gaasperdam SLIM naar QuickFit Aardgasvrij',
						href: 'https://www.cocratos.nl/slimwonengaasperdam/'
					},
					total: 255,
					goal: 2500
				}
			],
			headers: [
				{ text: 'Project', value: 'project', sortable: false },
				{ text: 'Voortgang', value: 'percentage', sortable: false },
				{ text: 'Opgehaald', value: 'total' },
				{ text: 'Doel', value: 'goal' }
			]
		}
	}),
	computed: {
		markers() {
			const data = []
			for (const id in assets) {
				const item = assets[id]
				data.push({
					title: item.location.name,
					startDate: item.startDate,
					capacity: item.capacity,
					action: () => {
						this.$store.dispatch('asset/FETCH', { id: Number(id) })
						this.$router.push({ name: 'Dashboard' })
					},
					marker: 'alt',
					...item.location
				})
				if (item.project.location) {
					data.push({
						title: item.project.name,
						description: item.project.description,
						...item.project.location
					})
				}
			}
			return data
		},
		projectItems() {
			const data = []
			for (const id in assets) {
				const item = assets[id]
				data.push({ yearTotalProduction: item.data._yearTotalProduction, ...item.project })
			}
			return data
		},
		contributionProjectData() {
			const data = {}
			for (const id in assets) {
				const item = assets[id]
				data[id] = this.getProduction(item.data._yearTotalProduction) * 0.035
			}
			return data
		},
		contributionProjectItems() {
			const data = []
			for (const id in assets) {
				const item = assets[id]
				data.push({
					x: item.project.name,
					y: this.getProduction(item.data._yearTotalProduction) * 0.035
				})
			}
			return [{ data }]
		},
		totalProduction() {
			let value = 0
			for (const id in assets) {
				value += this.getProduction(assets[id].data._yearTotalProduction)
			}
			return value
		}
	},
	created() {
		this.formatter = new NumberFormatter(this.$n.bind(this), { unit: 'wattHour' })
	},
	methods: {
		getProduction(total) {
			let value = 0

			const _d = new Date()
			const month = _d.getMonth()
			const date = _d.getDate()

			for (const id in monthModifier) {
				const current = Number(id)
				const target = monthModifier[id]
				if (current > month) break

				const modifier =
					current === month ? target.modifier * (date / target.days) : target.modifier

				value += total * modifier
			}

			return value
		}
	}
}
</script>
