<template>
	<l-map
		v-if="map.showMap"
		:zoom="mapZoom"
		:center="mapCenter"
		:options="map.mapOptions"
		@update:center="centerUpdate"
		@update:zoom="zoomUpdate"
		:style="{ 'min-height': height + 'px' }"
	>
		<l-tile-layer :url="map.url" :attribution="map.attribution" />
		<template v-for="(item, index) of markers">
			<l-marker
				:key="index"
				:lat-lng="getLatLng(item)"
				:icon="marker[item.marker ? item.marker : 'pin']"
				@click="item.action ? item.action() : () => {}"
			>
				<!-- TODO: Make this generic in the future (hardcoded at the moment) -->
				<l-tooltip v-if="item.title">
					<div class="font-weight-bold">
						{{ item.action ? 'PV Project' : 'Sociaal Project' }}
					</div>
					{{ item.title }}
					<div
						class="font-weight-light grey--text"
						:style="{ width: '250px', 'white-space': 'pre-line' }"
					>
						<template v-if="item.description">
							{{ item.description }}
						</template>
						<ul v-else>
							<li>
								Start deelname:
								<span class="light-blue--text" v-text="item.startDate" />
							</li>
							<li>
								Geïnstalleerd vermogen:
								<span class="light-blue--text" v-text="item.capacity" />
							</li>
						</ul>
					</div>
					<div v-if="item.action" class="font-weight-bold blue--text mt-1">
						Klik om locatie te selecteren
					</div>
				</l-tooltip>
			</l-marker>
		</template>
	</l-map>
</template>

<script>
import { Icon, latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet'

export default {
	props: {
		height: {
			default: 500
		},
		zoom: {
			default: 16
		},
		center: {
			default: () => ({
				latitude: 0,
				longitude: 0
			})
		},
		markers: {
			default: () => []
		}
	},
	computed: {
		mapZoom() {
			return this.zoom
		},
		mapCenter() {
			return latLng(this.center.latitude, this.center.longitude)
		}
	},
	data() {
		return {
			marker: {
				pin: undefined,
				alt: undefined
			},
			map: {
				url:
					'https://api.mapbox.com/styles/v1/spectralenergy/ckp3xfj1c7tiw18k80ks8tsbc/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3BlY3RyYWxlbmVyZ3kiLCJhIjoiY2tpazQ5em1tMDZiYjJ4cWpjdnB4cGltNCJ9.pkouUFgb4UwdXtWJodAutw',
				attribution:
					'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				mapOptions: {
					zoomSnap: 0.5
				},
				showMap: true
			}
		}
	},
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LTooltip
	},
	methods: {
		updateMapHeight() {
			this.height = this.$refs.mapContainer.offsetHeight
		},
		getLatLng({ latitude, longitude }) {
			return latLng(latitude, longitude)
		},
		zoomUpdate(zoom) {
			this.map.zoom = zoom
		},
		centerUpdate(center) {
			this.map.center = center
		}
	},
	created() {
		delete Icon.Default.prototype._getIconUrl
		Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
			iconUrl: require('leaflet/dist/images/marker-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png')
		})
		this.marker.pin = new Icon.Default({
			className: 'tooltip-highlight'
		})
		this.marker.alt = new Icon.Default({
			className: 'tooltip-highlight',
			iconSize: [41, 41],
			iconAnchor: [41, 40],
			shadowAnchor: [33, 40],
			iconRetinaUrl: require('/public/images/marker/marker-pv-icon-2x.png'),
			iconUrl: require('/public/images/marker/marker-pv-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png')
		})
	}
}
</script>
