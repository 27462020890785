export default {
	currency: {
		style: 'currency',
		currency: 'EUR',
		currencyDisplay: 'symbol'
	},
	currencyDecimals: {
		style: 'currency',
		currency: 'EUR',
		currencyDisplay: 'symbol',
		minimumFractionDigits: 2,
		maximumSignificantDigits: 20
	},
	number: {
		maximumSignificantDigits: 20
	},
	numberShort: {
		maximumSignificantDigits: 3
	},
	numberShorter: {
		maximumFractionDigits: 2
	}
}
