import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			name: 'Home',
			path: '/',
			redirect: {
				name: 'Overzicht'
			},
			meta: {
				assetSelection: false,
				hidden: true
			}
		},
		{
			name: 'Overzicht',
			path: '/overview',
			components: require('./pages/Overview'),
			meta: {
				assetSelection: false
			}
		},
		{
			name: 'Dashboard',
			path: '/dashboard',
			components: require('./pages/Dashboard'),
			meta: {
				assetSelection: true
			}
		},
		{
			name: 'Financiën',
			path: '/finance',
			components: require('./pages/Finance'),
			meta: {
				assetSelection: true
			}
		},
		{
			name: 'Rapportage',
			path: '/report',
			components: require('./pages/Report'),
			meta: {
				assetSelection: true
			}
		},
		{
			name: 'Profiel',
			path: '/profile',
			components: require('./pages/Profile'),
			meta: {
				assetSelection: false,
				hidden: true
			}
		},
		{
			name: 'Error',
			path: '*',
			redirect: {
				name: 'home'
			},
			meta: {
				assetSelection: false,
				hidden: true
			}
		}
	]
})
