<template>
	<div class="full">
		<apexchart
			ref="chart"
			v-show="ready"
			width="100%"
			height="100%"
			type="donut"
			:options="options"
			:series="series"
		/>
		<center-display
			v-if="!format.hidden"
			:style="{ 'padding-right': `${options.legend.width}px` }"
			class="no-pointer"
		>
			<formatter :options="format" :data="total" />
		</center-display>
	</div>
</template>

<script>
import CenterDisplay from '../CenterDisplay'
import Formatter from '../Formatter'
import ApexDefaultOptions from '../../constants/apex'
import deepmerge from 'deepmerge'
import NumberFormatter from '../../utils/NumberFormatter'

export default {
	name: 'Pie',
	props: ['config', 'data'],
	components: { CenterDisplay, Formatter },
	data() {
		return {
			ready: false,
			colors: [],
			series: [],
			total: 0,
			options: {},
			format: this.config.format || {}
		}
	},
	created() {
		this.options = deepmerge.all([
			ApexDefaultOptions,
			{
				plotOptions: {
					pie: {
						customScale: 0.82,
						donut: {
							size: '85%',
							labels: {
								show: false,
								name: {
									show: false
								},
								value: {
									show: false
								},
								total: {
									show: false,
									showAlways: false
								}
							}
						}
					}
				},
				tooltip: {
					y: {
						formatter: undefined
					}
				},
				legend: {
					width: 105
				}
			},
			this.config.options || {}
		])

		this.colors = this.config.series.map(e => e.color)
		this.options.labels = this.config.series.map(e => e.label)

		const formatter = new NumberFormatter(this.$n.bind(this), this.format)
		this.options.tooltip.y.formatter = formatter.getter()

		if (Array.isArray(this.options.yaxis)) {
			for (const index in this.options.yaxis) {
				if (!this.options.yaxis[index].labels) this.options.yaxis[index].labels = {}
				const localFormatter = new NumberFormatter(
					this.$n.bind(this),
					this.options.yaxis[index].format || this.format
				)
				this.options.yaxis[index].labels.formatter = localFormatter.getter()
			}
		} else {
			this.options.yaxis.labels.formatter = formatter.getter()
		}
	},
	mounted() {
		this.ready = true
		this.$refs.chart.updateOptions({ colors: this.colors })
		this.updateData(this.data)
	},
	watch: {
		data(data) {
			this.updateData(data)
		}
	},
	methods: {
		updateData(result) {
			if (result == undefined) return
			const series = []
			let total = 0
			for (const index in this.config.series) {
				let value
				if (result[this.config.series[index].value] != undefined) {
					value = result[this.config.series[index].value]
				} else {
					value = this.series[index] || 0
				}
				total += value
				series.push(value)
			}

			this.total = total
			this.$refs.chart.updateSeries(series)
		}
	}
}
</script>
