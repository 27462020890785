import se from '../../api-clients/se'

const FETCH = 'FETCH'
const FETCH_START = 'FETCH_START'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_ERROR = 'FETCH_ERROR'

const state = {
	// Current State
	fetching: false,
	error: null,

	// Data
	data: {}
}

const actions = {
	async [FETCH]({ commit }) {
		commit(FETCH_START)

		try {
			const user = await se.users.getCurrentUser()

			commit(FETCH_SUCCESS, user)
		} catch (error) {
			commit(FETCH_ERROR, error)
		}
	}
}

const mutations = {
	[FETCH_START](state) {
		state.fetching = true
	},
	[FETCH_SUCCESS](state, data) {
		state.fetching = false
		state.error = null
		state.data = data
	}
}

class User {
	constructor() {
		this.namespaced = true
		this.state = state
		this.actions = actions
		this.mutations = mutations
	}
}

export default User
