var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c('b',{domProps:{"textContent":_vm._s(_vm.timeLabel)}}),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"dense":"","borderless":"","background-color":"light-green lighten-5 text--grey text--darken-4","color":"light-green"},model:{value:(_vm.selectedRange),callback:function ($$v) {_vm.selectedRange=$$v},expression:"selectedRange"}},[_vm._l((_vm.ranges),function(item){return [(_vm.timeRange.range.available.includes(item.value))?_c('v-btn',{key:item.value,on:{"click":function($event){return _vm.setRange(item.value)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()]}),(_vm.timeRange.range.available.includes('custom'))?_c('v-btn',{staticClass:"daterange-button"},[_c('date-range-picker',{ref:"picker",attrs:{"locale-data":{
					firstDay: 1,
					separator: ' — ',
					monthNames: Object.values(_vm.$t('month.long')),
					daysOfWeek: Object.values(_vm.$t('week.short')),
					customRangeLabel: _vm.$t('datePicker.label.customRange'),
					cancelLabel: _vm.$t('datePicker.label.cancel'),
					applyLabel: _vm.$t('datePicker.label.apply'),
					weekLabel: _vm.$t('datePicker.label.week'),
					format: _vm.$t(
						("datePicker.preview." + (_vm.settings.timePicker ? 'dateTime' : 'date'))
					)
				},"opens":'left',"singleDatePicker":_vm.settings.singleDatePicker != undefined ? _vm.settings.singleDatePicker : false,"timePicker":_vm.settings.timePicker != undefined ? _vm.settings.timePicker : true,"timePickerIncrement":1,"timePickerSeconds":true,"timePicker24Hour":true,"showWeekNumbers":true,"showDropdowns":true,"autoApply":false,"ranges":false,"linkedCalendars":true},on:{"update":_vm.setTimeRange},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}},[_c('div',{attrs:{"slot":"input"},slot:"input"},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-calendar')}})],1)])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }