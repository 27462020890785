<template>
	<div
		:class="{ 'display-container': enabled, relative: relative }"
		:style="{
			'justify-content': horizontal,
			'align-items': vertical,
			'flex-direction': direction
		}"
	>
		<div v-if="!multipleBlocks" class="display">
			<slot />
		</div>
		<slot v-else />
	</div>
</template>

<style lang="scss">
.display-container {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;
	left: 0;

	&.relative {
		position: relative;
	}

	.display {
		display: block;
		text-align: center;
		width: 100%;
	}
}
</style>

<script>
export default {
	props: {
		multipleBlocks: {
			type: Boolean,
			default: false
		},
		enabled: {
			type: Boolean,
			default: true
		},
		direction: {
			type: String,
			default: 'row'
		},
		vertical: {
			type: String,
			default: 'center'
		},
		relative: {
			type: Boolean,
			default: false
		},
		horizontal: {
			type: String,
			default: 'center'
		}
	}
}
</script>
