<template>
	<v-container v-if="asset.data.location">
		<v-dialog v-model="editDialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Instellingen</span>
				</v-card-title>
				<v-card-text class="pb-2">
					<v-row class="mt-0">
						<v-col class="pb-0 pt-0">
							<div class="subtitle-2">Bijdrage Sociale Energie (per kWh)</div>
							<v-slider
								v-if="asset.data.cut && asset.data.cut.max"
								v-model="config.share.value"
								track-color="grey lighten-2"
								color="light-green"
								:min="asset.data.cut.min"
								:max="asset.data.cut.max"
								class="align-center"
								hide-details
							>
								<template v-slot:append>
									<v-text-field
										v-model="config.share.value"
										prepend="mdi-currency"
										class="mt-0 pt-0 d-flex align-center"
										:min="asset.data.cut.min"
										:max="asset.data.cut.max"
										type="number"
										color="light-green"
										hide-details
									>
										<template v-slot:prepend>
											€
										</template>
									</v-text-field>
								</template>
							</v-slider>
							<v-slider
								v-else
								v-model="config.share.value"
								class="caption"
								track-color="grey lighten-2"
								:max="0.06"
								:min="0.02"
								:step="0.01"
								ticks="always"
								color="light-green"
								:tick-labels="config.share.ticksLabels"
								tick-size="4"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="pb-0">
							<div class="subtitle-2">Sociaal project</div>
							<v-select
								v-model="config.project.value"
								:items="config.project.items"
								item-color="light-green"
								dense
								outlined
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pb-4">
					<v-btn color="light-green lighten-4" elevation="0" @click="updateSettings">
						Wijzigingen Opslaan
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn text @click="editDialog = false">
						Annuleer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="d-flex flex-nowrap">
			<tile>
				<v-row>
					<v-col>
						<leaflet-map
							class="rounded"
							:style="{ 'z-index': 0 }"
							:height="220"
							:zoom="map.zoom"
							:center="asset.data.location"
							:markers="[{ ...asset.data.location, marker: 'alt' }]"
						/>
					</v-col>
					<v-col class="d-flex flex-column justify-center">
						<div class="mb-2">
							<div class="subtitle-2">Locatie zonnedak</div>
							<div class="body-2">
								{{ asset.data.location.name }}
							</div>
						</div>
						<div class="mb-2">
							<div class="subtitle-2">Start deelname Sociale Energie</div>
							<div class="body-2">
								{{ asset.data.startDate }}
							</div>
						</div>
						<div class="mb-2">
							<div class="subtitle-2">Geïnstalleerd vermogen</div>
							<div class="body-2">
								{{ asset.data.capacity }}
							</div>
						</div>
						<div class="mb-2">
							<div class="subtitle-2">Vergoedingsstructuur</div>
							<div class="body-2">
								{{ asset.data.reimbursementStructure }}
							</div>
						</div>
						<div class="mb-2">
							<div class="subtitle-2">Bijdrage Sociale Energie</div>
							<div class="body-2">
								{{ $n(asset.data.cut.value, 'currency') }}
								{{ asset.data.cut.suffix }}
							</div>
						</div>
						<div class="mb-2">
							<div class="subtitle-2">Sociaal project</div>
							<div class="body-2">
								<v-btn
									color="primary"
									text
									small
									target="_blank"
									:href="asset.data.project.href"
								>
									{{ asset.data.project.name }}
									<v-icon class="ml-2" v-text="'mdi-link'" />
								</v-btn>
							</div>
						</div>
						<v-btn
							color="light-green lighten-4"
							elevation="0"
							@click="editDialog = true"
						>
							Wijzig
						</v-btn>
					</v-col>
				</v-row>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile title="Project voortgang">
				<v-progress-linear
					color="light-green"
					rounded
					height="25"
					:value="(socialEnergyCut / asset.data.project.goal) * 100"
				>
					<template v-slot:default="{ value }">
						<strong>{{ Math.ceil(value) }}%</strong>
					</template>
				</v-progress-linear>
				<div class="d-flex align-center mt-1">
					<h4 class="grey--text">
						{{ $n(socialEnergyCut, 'currency') }}
					</h4>
					<v-spacer />
					<h4 class="grey--text">
						{{ $n(asset.data.project.goal, 'currency') }}
					</h4>
				</div>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile
				title="Bijdrage Sociale Energie"
				tooltip="Gebaseerd op deze uitleg die hier geschreven staat maar eigenlijk een
					placeholder is"
			>
				<h1 class="d-inline text--gradient--green">
					{{ $n(socialEnergyCut, 'currency') }}
				</h1>
			</tile>
			<tile
				title="Verwachte jaaropbrengst"
				tooltip="Gebaseerd op deze uitleg die hier geschreven staat maar eigenlijk een
					placeholder is"
			>
				<h1 class="d-inline text--gradient--green">
					{{ $n(asset.data.data._yearTotalProduction * 0.035, 'currency') }}
				</h1>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile title="Transacties">
				<v-data-table
					:headers="transactions.headers"
					:items="transactionItems"
					:items-per-page="-1"
					class="elevation-0"
				>
					<template v-slot:item.volume="{ item }">
						{{ formatter.get(item.volume) }}
					</template>
					<template v-slot:item.social_total="{ item }">
						{{ $n(item.volume * 0.035, 'currency') }}
					</template>
					<template v-slot:item.other_total="{ item }">
						{{ $n(item.volume * 0.015, 'currency') }}
					</template>
					<template v-slot:item.total="{ item }">
						{{ $n(item.volume * 0.05, 'currency') }}
					</template>
				</v-data-table>
			</tile>
		</v-row>
	</v-container>
</template>

<script>
// Utils
import { mapState } from 'vuex'
import NumberFormatter from '../../utils/NumberFormatter'

// Data
import assets from '../../constants/_assets'
import monthModifier from '../../constants/_monthModifier'

// Components
import Tile from '../../components/Tile'
import LeafletMap from '../../components/LeafletMap'

export default {
	name: 'Finance',
	components: { Tile, LeafletMap },
	data: () => ({
		editDialog: false,
		map: {
			zoom: 16,
			center: { latitude: 52.394334, longitude: 4.909462 }
		},
		transactions: {
			headers: [
				{ text: 'Date', value: 'date' },
				{ text: 'Opgewekt (kWh)', value: 'volume' },
				{ text: 'Sociale Energie (€)', value: 'social_total' },
				{ text: 'Overig (€)', value: 'other_total' },
				{ text: 'Totaal (€)', value: 'total' }
			]
		},
		config: {
			share: {
				value: 0.03,
				ticksLabels: ['€ 0,02', '€ 0,03', '€ 0,04', '€ 0,05', '€ 0,06']
			},
			project: {
				value: 'Wijkcentrum de Pijp',
				items: [
					'Wijkcentrum de Pijp',
					'Denktank Schone Start',
					'Gaasperdam SLIM naar QuickFit Aardgasvrij'
				]
			}
		}
	}),
	computed: {
		...mapState(['asset']),
		socialEnergyCut() {
			return this.asset.data.data._yearTotalProduction * this.yearModifier * 0.035
		},
		yearModifier() {
			let modifier = 0

			const _d = new Date()
			const month = _d.getMonth()
			const date = _d.getDate()

			for (const id in monthModifier) {
				const current = Number(id)
				const target = monthModifier[id]
				if (current > month) break

				modifier +=
					current === month ? target.modifier * (date / target.days) : target.modifier
			}

			return modifier
		},
		transactionItems() {
			const data = []

			const _d = new Date()
			const year = _d.getFullYear()
			const month = _d.getMonth()
			const date = _d.getDate()

			for (const id in monthModifier) {
				const current = Number(id)
				const target = monthModifier[id]
				if (current > month) break

				const modifier =
					current === month ? target.modifier * (date / target.days) : target.modifier

				const entry = {
					date: `${target.name} ${year}`,
					volume: this.asset.data.data._yearTotalProduction * modifier
				}

				data.push(entry)
			}

			return data
		}
	},
	watch: {
		'asset.data'() {
			this.updateAssetValue()
			this.updateProjectValue()
		}
	},
	created() {
		this.formatter = new NumberFormatter(this.$n.bind(this), { unit: 'wattHour' })
	},
	mounted() {
		this.updateAssetValue()
		this.updateProjectValue()
	},
	methods: {
		updateSettings() {
			let project = {}

			for (const id in assets) {
				const target = assets[id]
				// eslint-disable-next-line no-console
				console.log(target.project.name)
				if (target.project.name !== this.config.project.value) continue
				project = JSON.parse(JSON.stringify(target.project))
				break
			}

			this.$store.dispatch('asset/UPDATE_SETTINGS', { project, cut: this.config.share.value })
			this.editDialog = false
		},
		updateAssetValue() {
			if (this.asset.data && this.asset.data.cut && this.asset.data.cut.value != null) {
				this.config.share.value = this.asset.data.cut.value
			}
		},
		updateProjectValue() {
			if (this.asset.data && this.asset.data.project) {
				this.config.project.value = this.asset.data.project.name
			}
		}
	}
}
</script>
