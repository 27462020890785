<template>
	<v-container v-if="asset.data.data">
		<v-row class="d-flex flex-nowrap">
			<tile>
				<date-toolbar
					:time-range="timeRange"
					@set-range="setRange"
					@set-time-range="setTimeRange"
				/>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile title="Opwek" icon="mdi-solar-panel">
				<div>
					<line-graph
						:config="config.graph"
						:data="asset.data.data.day.graph"
						:style="{ height: '350px' }"
					/>
				</div>
			</tile>
		</v-row>
		<v-row class="d-flex flex-nowrap">
			<tile
				title="Bijdrage Sociale Energie"
				tooltip="Gebaseerd op deze uitleg die hier geschreven staat maar eigenlijk een
					placeholder is"
			>
				<h1 class="d-inline text--gradient--green">
					{{ $n(socialEnergyCut, 'currency') }}
				</h1>
			</tile>
			<tile
				title="Opwek"
				tooltip="Gebaseerd op deze uitleg die hier geschreven staat maar eigenlijk een
					placeholder is"
			>
				<h1 class="d-inline text--gradient--green">
					{{ formatter.get(asset.data.data._yearTotalProduction * todayModifier) }}
				</h1>
			</tile>
		</v-row>
	</v-container>
</template>

<script>
// Utils
import moment from 'moment'
import { mapState } from 'vuex'
import NumberFormatter from '../../utils/NumberFormatter'

// Data
import monthModifier from '../../constants/_monthModifier'

// Components
import Tile from '../../components/Tile'
import DateToolbar from '../../components/DateToolbar'
import LineGraph from '../../components/LineGraph'

export default {
	name: 'Dashboard',
	components: { Tile, DateToolbar, LineGraph },
	data: () => ({
		timeRange: {
			from: moment().startOf('day'),
			to: moment().endOf('day'),
			range: {
				available: ['day', 'week', 'month', 'year', 'custom'],
				selected: 'day'
			}
		},
		config: {
			graph: {
				legend: true,
				format: {
					decimals: 2,
					unit: 'watt'
				},
				options: {
					stroke: {
						curve: 'smooth'
					}
				},
				series: [
					{
						color: '#A2CD3A',
						label: 'Zonnepanelen',
						value: 'pvPanels',
						format: {
							decimals: 2,
							unit: 'watt'
						}
					}
				]
			}
		}
	}),
	computed: {
		...mapState(['asset']),
		socialEnergyCut() {
			return this.asset.data.data._yearTotalProduction * this.todayModifier * 0.035
		},
		todayModifier() {
			const _d = new Date()
			const target = monthModifier[_d.getMonth()]

			return target.modifier * (1 / target.days)
		}
	},
	created() {
		this.formatter = new NumberFormatter(this.$n.bind(this), { unit: 'wattHour' })
	},
	methods: {
		setRange({ range, currentWhenSame }) {
			if (!this.timeRange.range.available.includes(range)) return
			if (range === 'custom') return

			const sameRange = this.timeRange.range.selected === range && currentWhenSame
			const date = sameRange ? moment() : moment(this.timeRange.from)

			switch (range) {
				case 'day':
					this.setTimeRange({
						from: moment(date).startOf('day'),
						to: moment(date).endOf('day'),
						selectedRange: range
					})
					break
				case 'week':
					this.setTimeRange({
						from: moment(date)
							.startOf('week')
							.startOf('day'),
						to: moment(date)
							.endOf('week')
							.endOf('day'),
						selectedRange: range
					})
					break
				case 'month':
					this.setTimeRange({
						from: moment(date)
							.startOf('month')
							.startOf('day'),
						to: moment(date)
							.endOf('month')
							.endOf('day'),
						selectedRange: range
					})
					break
				case 'year':
					this.setTimeRange({
						from: moment(date)
							.startOf('year')
							.startOf('day'),
						to: moment(date)
							.endOf('year')
							.endOf('day'),
						selectedRange: range
					})
					break
				default:
					return
			}
		},
		setTimeRange({ from, to, selectedRange }) {
			this.timeRange.from = moment(from)
			this.timeRange.to = moment(to)
			this.timeRange.range.selected = selectedRange
		}
	}
}
</script>
